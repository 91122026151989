<template>
  <div>
    <a-spin :spinning="loading">
      <div ref="editor" class="editor-wrapper" />
    </a-spin>
  </div>
</template>

<script>
import WEditor from 'wangeditor'
import { getResourceURL, getUploadToken } from '@/api/upload'

export default {
  name: 'WangEditor',
  props: {
    // eslint-disable-next-line
    value: {
      type: String
    }
  },
  data() {
    return {
      loading: true,
      editor: null,
      editorContent: null,
      uploadParams: {}
    }
  },
  watch: {
    value(val) {
      // this.editor.txt.html('') 光标会跳到最后, 增加判断如果内容相同则不做js加载内容
      if (this.editorContent !== val) {
        this.editorContent = val
        this.editor && this.editor.txt.html(this.editorContent)
      }
    }
  },
  created() {
    this.initEditor()
  },
  methods: {
    initEditor() {
      this.loading = true
      getUploadToken().then(res => {
        if (res.code === 0) {
          this.uploadParams = res.data
          this.uploadParams.storage_method_slug = 'local'
          this.createEditor()
          // 赋值(由于是先调接口后初始化 editor, watch 中 this.editor 并未初始化好, 所以这里手动赋值)
          if (this.editorContent) {
            this.editor.txt.html(this.editorContent)
          }
          this.loading = false
        }
      })
    },

    createEditor() {
      this.editor = new WEditor(this.$refs.editor)
      this.editor.config.onchange = (html) => {
        this.editorContent = html
        this.$emit('change', html)
      }

      this.editor.config.uploadImgServer = this.uploadParams.upload_url
      this.editor.config.uploadImgParams = this.uploadParams
      this.editor.config.uploadImgMaxLength = 1
      this.editor.config.uploadFileName = 'file'
      this.editor.config.uploadImgHooks = {
        // 图片上传并返回了结果，把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function(insertImgFn, response) {
          // response 即服务端返回的接口
          getResourceURL(response).then((res) => {
            if (res.code === 0) {
              // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
              insertImgFn(res.data.resource_url)
            }
          })
        }
      }
      this.editor.create()
    }
  }
}
</script>

<style lang="less" scoped>
.editor-wrapper {
  text-align: left;
}
</style>
